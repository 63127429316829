import React from 'react'
import Helmet from 'react-helmet'
import config from '../../utils/siteConfig'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer'
import './Layout.scss'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 800,
  })
}

const Template = ({ children, headerType }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <script type="text/javascript" src="/initClutch.js" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link
          rel="stylesheet"
          href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/default.min.css"
        />
        <script
          type="text/javascript"
          src="//script.crazyegg.com/pages/scripts/0103/4402.js"
          async="async"
        />
        <link rel="stylesheet" href="https://use.typekit.net/uhl4uur.css" />
        <meta name="description" content={config.siteDescription} />
        <meta property="og:title" content={config.siteTitle} />
        <meta property="og:url" content={config.siteUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={config.siteTitle} />
        <script src="https://www.google.com/recaptcha/api.js" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-L88X444RQ2"
        />
        <script type="text/javascript" src="/initGTag.js" />
      </Helmet>
      <div className="siteContent">
        <Header type={headerType} />
        {children}
      </div>
      <Footer />
      <script type="text/javascript" src="/initLITag.js" />
    </div>
  )
}

//<script type="text/javascript" src="/initAutopilot.js" />

export default Template
