import React from 'react'
import './HeaderDropdown.scss'

class HeaderDropdown extends React.Component {
  render() {
    return (
      <ul className="header-dropdown">
        <li className="item ">
          <a href="/cloudsecops">DevSecOps</a>
        </li>
        <li className="item ">
          <a href="/digitalagility">AI & Platform</a>
        </li>
        <li className="item ">
          <a href="/mobile">Edge, IoT/Mobile, and VR</a>
        </li>
      </ul>
    )
  }
}

export default HeaderDropdown
