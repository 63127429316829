import React from 'react'
import { Link } from 'gatsby'
import { FaBars, FaTimes, FaRegEnvelope } from 'react-icons/fa'
import './Header.scss'
import HeaderDropdown from '../HeaderDropdown/HeaderDropdown'
import HeaderDropdownSol from '../HeaderDropdownSol/HeaderDropdownSol'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAboutMenu: false,
      showASol: false,
      mobileMenu: false,
    }
    this.initClutch()
  }

  componentDidMount() {
    this.initClutch()
  }

  initClutch() {
    setTimeout(() => {
      if (
        typeof document !== 'undefined' &&
        typeof window !== 'undefined' &&
        typeof window.CLUTCHCO !== 'undefined'
      ) {
        window.CLUTCHCO.Init()
      }
    }, 200)
  }

  handleHover = event => {
    this.setState({ showAboutMenu: true })
  }

  handleLeave = event => {
    this.setState({ showAboutMenu: false })
  }

  handleHoverSol = event => {
    this.setState({ showASol: true })
  }

  handleLeaveSol = event => {
    this.setState({ showASol: false })
  }

  toggleMenu() {
    this.setState({
      mobileMenu: !this.state.mobileMenu,
    })
  }

  render() {
    return (
      <header className={this.state.mobileMenu ? '' : this.props.type}>
        <nav>
          <Link to="/">
            <div className="logo-wrap">
              {this.props.type &&
              !this.state.mobileMenu &&
              this.props.type === 'clear' ? (
                <img src="/logos/RacknerLogoWhite.png" />
              ) : (
                <img src="/logos/RacknerLogo.png" />
              )}
            </div>
          </Link>
          <div className="buffer" />
          <div className="stacked-nav">
            <div className="super-nav">
              <ul>
                <li>
                  <a href="https://boards.greenhouse.io/rackner">Careers</a>
                </li>
                <li>
                  <Link to="/training">Training</Link>
                </li>
                <li>
                  <Link to="/contractvehicles">Contracts</Link>
                </li>
                <Link to="/contact">
                  <li className="contact-button">
                    <FaRegEnvelope />
                    Contact
                  </li>
                </Link>
              </ul>
            </div>
            <div className="base-nav">
              <ul>
                <li onMouseLeave={this.handleLeave.bind(this)}>
                  <a
                    href="/services"
                    onMouseEnter={this.handleHover.bind(this)}
                  >
                    Services
                  </a>
                  <div className="submenu-container">
                    {this.state.showAboutMenu && <HeaderDropdown />}
                  </div>
                </li>
                <li onMouseLeave={this.handleLeaveSol.bind(this)}>
                  <a onMouseEnter={this.handleHoverSol.bind(this)}>Solutions</a>
                  <div className="submenu-container">
                    {this.state.showASol && <HeaderDropdownSol />}
                  </div>
                </li>
                <li>
                  <Link to="/insights/">Insights</Link>
                </li>
                <li>
                  <Link to="/about/">About</Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            onClick={this.toggleMenu.bind(this)}
            className="mobile-menu-button"
          >
            {this.state.mobileMenu ? <FaTimes /> : <FaBars />}
          </div>
          <div
            className={
              'mobile-menu-dropdown ' + (this.state.mobileMenu ? 'active' : '')
            }
          >
            <ul>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <a href="/services">Services</a>
              </li>
              {/* <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/solutions/">Solutions</Link>
              </li> */}
              
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/insights/">Insights</Link>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/about/">About</Link>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/boost/">Boost</Link>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/kubernetes/">Kubernetes</Link>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/covalent/">Covalent</Link>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <a href="https://boards.greenhouse.io/rackner">Careers</a>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/contractvehicles">Contract Vehicles</Link>
              </li>
              <li
                onClick={this.toggleMenu.bind(this)}
                className={this.state.mobileMenu ? 'active' : ''}
              >
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
