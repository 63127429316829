import React from 'react'
import './HeaderDropdown.scss'

class HeaderDropdownSol extends React.Component {
  render() {
    return (
      <ul className="header-dropdown">
        <li className="item ">
          <a href="/covalent">Enclave AI: AI from edge to airgap</a>
        </li>
        <li className="item ">
          <a href="/kubernetes">Kubernetes</a>
        </li>
        <li className="item ">
          <a href="/boost/">Boost: Product Accelerator</a>
        </li>
      </ul>
    )
  }
}

export default HeaderDropdownSol
